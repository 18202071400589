exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-banachiewicz-js": () => import("./../../../src/pages/banachiewicz.js" /* webpackChunkName: "component---src-pages-banachiewicz-js" */),
  "component---src-pages-bio-js": () => import("./../../../src/pages/bio.js" /* webpackChunkName: "component---src-pages-bio-js" */),
  "component---src-pages-bio-pl-js": () => import("./../../../src/pages/bio-pl.js" /* webpackChunkName: "component---src-pages-bio-pl-js" */),
  "component---src-pages-book-js": () => import("./../../../src/pages/book.js" /* webpackChunkName: "component---src-pages-book-js" */),
  "component---src-pages-esperanto-js": () => import("./../../../src/pages/esperanto.js" /* webpackChunkName: "component---src-pages-esperanto-js" */),
  "component---src-pages-forensics-js": () => import("./../../../src/pages/forensics.js" /* webpackChunkName: "component---src-pages-forensics-js" */),
  "component---src-pages-forensyka-js": () => import("./../../../src/pages/forensyka.js" /* webpackChunkName: "component---src-pages-forensyka-js" */),
  "component---src-pages-gap-junction-js": () => import("./../../../src/pages/gap-junction.js" /* webpackChunkName: "component---src-pages-gap-junction-js" */),
  "component---src-pages-gap-junction-pl-js": () => import("./../../../src/pages/gap-junction-pl.js" /* webpackChunkName: "component---src-pages-gap-junction-pl-js" */),
  "component---src-pages-gp-bookcover-js": () => import("./../../../src/pages/gp-bookcover.js" /* webpackChunkName: "component---src-pages-gp-bookcover-js" */),
  "component---src-pages-gp-exodus-js": () => import("./../../../src/pages/gp-exodus.js" /* webpackChunkName: "component---src-pages-gp-exodus-js" */),
  "component---src-pages-gp-frontcover-js": () => import("./../../../src/pages/gp-frontcover.js" /* webpackChunkName: "component---src-pages-gp-frontcover-js" */),
  "component---src-pages-gp-mural-js": () => import("./../../../src/pages/gp-mural.js" /* webpackChunkName: "component---src-pages-gp-mural-js" */),
  "component---src-pages-gp-rosegarden-js": () => import("./../../../src/pages/gp-rosegarden.js" /* webpackChunkName: "component---src-pages-gp-rosegarden-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kazimierz-kordylewski-js": () => import("./../../../src/pages/kazimierz-kordylewski.js" /* webpackChunkName: "component---src-pages-kazimierz-kordylewski-js" */),
  "component---src-pages-links-js": () => import("./../../../src/pages/links.js" /* webpackChunkName: "component---src-pages-links-js" */),
  "component---src-pages-publications-js": () => import("./../../../src/pages/publications.js" /* webpackChunkName: "component---src-pages-publications-js" */),
  "component---src-pages-sputnik-js": () => import("./../../../src/pages/sputnik.js" /* webpackChunkName: "component---src-pages-sputnik-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */)
}

